import React, { useEffect, useState } from "react"
import { Button, createStyles, Divider, Grid, makeStyles, Theme, Typography, LinearProgress, TextField } from "@material-ui/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactTyped from "react-typed";
import { navigate } from "gatsby";
import { getPortfolio, getServices } from '../utils/functions';
import { IProject, IService } from "../utils/interfaces";
import { CMS_ROUTE } from "../utils/constants";
import { Send } from "@material-ui/icons";
import { FormEvent } from "c:/users/axel.leon/appdata/local/microsoft/typescript/4.3/node_modules/@types/react";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: '100vh',
      position: 'relative'
    },
    headerContainer: {
      bottom: 150,
      width: '100%',
      position: 'absolute',
      '& > *': {
        marginBottom: theme.spacing(6),
        '&:last-child': {
          marginBottom: theme.spacing(0)
        }
      }
    },
    address: {
      fontSize: 17.5,
      color: theme.palette.primary.main,
      textAlign: 'right'
    },
    title: {
      maxWidth: 800
    },
    slogan: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      '& span': {
        color: theme.palette.primary.main
      }
    },
    contact: {
      fontSize: 20
    },
    main: {
      '& > div': {
        marginTop: theme.spacing(8),
        '&:nth-child(1)': {
          marginTop: 0
        }
      }
    },
    services: {
      marginTop: theme.spacing(2)
    },
    number: {
      color: theme.palette.primary.main,
      '& hr': {
        width: 80,
        height: 2,
        backgroundColor: theme.palette.primary.main
      }
    },
    works: {
      marginTop: theme.spacing(2),
      '& p': {
        color: theme.palette.grey[500]
      },
      '& > div:nth-child(odd)': {
        marginRight: 'auto',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
          '& > div > div:nth-child(1)': {
            order: 1,
          }
        }
      },
      '& > div:nth-child(even)': {
        marginLeft: 'auto',
        textAlign: 'right'
      }
    },
    weAre: {
      marginTop: theme.spacing(2)
    },
    we: {
      fontSize: 18,
      letterSpacing: theme.spacing(.07)
    },
    send: {
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(2)
    }
  })
);

const Index = () => {
  const classes = useStyles();
  const [services, setServices] = useState<Array<IService>>([]);
  const [portfolio, setPortfolio] = useState<Array<IProject>>([]);
  const [loading, setLoading] = useState(true);
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: ""
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        setServices(await getServices());
        setPortfolio(await getPortfolio(3));
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  const sendContactForm = async (e: FormEvent) => {
    e.preventDefault();
    if (!contactFormData.name) return Swal.fire("Por favor, coloque su nombre.");
    if (!contactFormData.email) return Swal.fire("Por favor, coloque su correo electrónico.");
    if (!contactFormData.phone) return Swal.fire("Por favor, coloque su número de teléfono.");
    if (!contactFormData.message) return Swal.fire("Por favor, coloque su mensaje.");
    
    try {
      // Validar recaptcha
      const captchaResult = await executeRecaptcha('homepage');
      const resRecaptchaValidation = await axios.post(`${CMS_ROUTE}/recaptcha_validation.php`, {token: captchaResult}, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (resRecaptchaValidation.data.error) return Swal.fire({
        icon: "error",
        text: "Algo ha salido mal al enviar el formulario. Por favor, envíenos un correo electrónico a info@nodeaxan.com",
        title: "Error",
        footer: resRecaptchaValidation.data.message
      })

      const res = await axios.post(`${CMS_ROUTE}/main/api/forms/submit/contact`, {
        form: contactFormData
      } ,{
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer 2fba9238bae4513f02ef33f7eebfae"
        }
      })
      if (res.status === 200){
        Swal.fire({
          icon: 'success',
          text: "Mensaje enviado correctamente"
        })
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Algo ha salido mal al enviar el formulario. Por favor, envíenos un correo electrónico a info@nodeaxan.com",
        title: "Error",
        footer: `${error}`
      })
    }
  }

  return (
    <Layout page='Inicio'>
      <SEO title="Inicio" />
      <header className={classes.header}>
        <div className={classes.headerContainer}>
          <Typography variant='body1' className={classes.address}>
            Villahermosa, Tabasco.
          </Typography>

          <Typography variant='h1' className={classes.title}>Si puedes imaginarlo, podemos crearlo</Typography>

          <Typography variant='h4' className={classes.slogan}>
            Creamos <span> </span>
            <ReactTyped strings={[
              'sistemas',
              'mejoras',
              'soluciones'
            ]} typeSpeed={70} backSpeed={70} loop ></ReactTyped>
          </Typography>

          <Button onClick={() => window.location.href = "mailto:info@nodeaxan.com"} className={classes.contact} variant='contained' color='primary'>Contáctanos</Button>
        </div>
      </header>

      <main className={classes.main}>
        {/* servicios */}
        <div>
          <Typography variant='h2'>Servicios</Typography>

          <Grid container spacing={5} className={classes.services}>
            {loading ? <LinearProgress /> :
              services.map((service, index) => (
                <Grid key={service._id} item xs={12} sm={6} md={4}>
                  <Grid container alignItems='center' spacing={2} className={classes.number}>
                    <Grid item>
                      <Typography variant='body1'>
                        {(index + 1) <= 9 ? `0${(index + 1)}` : (index + 1)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant='h3'>{service.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {service.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </div>

        {/* last works */}
        <div>
          <Typography variant='h2'>Últimos trabajos</Typography>

          <Grid container spacing={5} className={classes.works}>
            {portfolio.map(project => (
              <Grid key={project._id} item xs={12} sm={10}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} container spacing={2} direction='column'>
                    <Grid item>
                      <Typography variant='h3'>{project.project_name}</Typography>
                    </Grid>
                    <Grid item>
                      <div>{project.summary}</div>
                    </Grid>
                    <Grid item>
                      <Button onClick={() => navigate(`/portafolio/item?id=${project._id}`)} variant='contained' color='primary'>Ver más</Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={`${CMS_ROUTE}${project.main_image.path}`} style={{ maxHeight: "300px", width: "auto" }} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* we are */}
        <div>
          <Typography variant='h2'>¿Quiénes somos?</Typography>

          <Grid container spacing={5} className={classes.weAre}>
            <Grid item md={12}>
              <Typography variant='body1' className={classes.we}>
                Somos desarrolladores apasionados por la creación de soluciones digitales. Estamos sumamente ligados al desarrollo tecnológico de diversas índoles, y deseamos extender el control de la información de las personas por todo México.
              </Typography>
              <Button variant="contained" onClick={() => navigate("/about")}>Ver más</Button>
            </Grid>
            <Grid item md={6}>
              <Typography variant='body1' className={classes.send}>
                ¿Quieres hablar con nosotros? <a href="mailto:info@nodeaxan.com">Envíanos un correo electrónico.</a> Serás atendido en breve. <br/>
                O bien, rellena el siguiente formulario:
              </Typography>

            </Grid>
          </Grid>
          <form onSubmit={sendContactForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant='outlined'
                  label='Su nombre'
                  margin='dense'
                  name='name'
                  fullWidth
                  required
                  value={contactFormData.name}
                  onChange={e => setContactFormData({...contactFormData, name: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant='outlined'
                  label='Su correo electrónico'
                  margin='dense'
                  name='mail'
                  type="email"
                  fullWidth
                  required
                  value={contactFormData.email}
                  onChange={e => setContactFormData({...contactFormData, email: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant='outlined'
                  label='Su número de teléfono'
                  margin='dense'
                  name='phone'
                  type="phone"
                  fullWidth
                  required
                  value={contactFormData.phone}
                  onChange={e => setContactFormData({...contactFormData, phone: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  label='Mensaje'
                  multiline
                  rows={4}
                  margin='dense'
                  name='message'
                  fullWidth
                  value={contactFormData.message}
                  onChange={e => setContactFormData({...contactFormData, message: e.target.value})}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" color="primary" variant="contained" endIcon={<Send />}>Enviar</Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </main>
    </Layout>
  )
};

export default Index
